import React, { useRef, useState, useEffect, createRef } from "react";
import ReactDOM, { findDOMNode } from "react-dom";
import { graphql, Link, useScrollRestoration } from "gatsby";
import styled from "styled-components";
import { withPreview } from "gatsby-source-prismic";
import { ImageOrientation } from "../components/utils/image-orientation";
import { useMediaQuery } from "../components/tf/media-query";
import { Helmet } from "react-helmet";
import { random } from "lodash";

const IndexPageCon = styled.div`
  width: calc(100% - 20px);
  height: calc(100vh - 20px);
  margin: 10px;
  position: relative;
`;
const NavCon = styled.nav`
  display: inline-block;
  position: relative;
  vertical-align: top;
  z-index: 2000;
  @media (max-width: 640px) {
    position: relative;
  }
`;

const ImgCon = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  z-index: 1000;
`;

const ProjectListCon = styled.div`
  margin-left: 10vw;
  display: inline-block;
  position: relative;
  z-index: 2000;
  @media (max-width: 640px) {
    margin-left: 0vw;
    padding-top: 20px;
  }
`;

const LiProjectsList = styled.li`
  font-size: 18px;
  font-family: "Unica77LLWeb-Bold", helvetica;
  line-height: 1;
  letter-spacing: -1px;
  font-weight: bold;
  opacity: 0.6;
  cursor: pointer;
  color: white;
  display: inline-block;
  @media (hover) {
    &:hover {
      opacity: 1;
    }
  }
  @media (min-width: 1200px) {
    font-size: 24px;
  }
  @media (min-width: 1024px) and (orientation: portrait) {
    font-size: 24px;
  }
  @media (max-width: 500px) {
    font-size: 24px;
  }
`;

const LiNavList = styled.li`
  font-size: 18px;
  font-family: "Unica77LLWeb-Bold", helvetica;
  line-height: 1;
  letter-spacing: -1px;
  font-weight: bold;
  cursor: pointer;
  color: white;
  opacity: 0.6;
  &:hover {
    opacity: 1;
  }
  @media (min-width: 1200px) {
    font-size: 24px;
  }
  @media (min-width: 1024px) and (orientation: portrait) {
    font-size: 24px;
  }
  @media (max-width: 500px) {
    font-size: 24px;
  }
`;

const PagingCon = styled.div`
  position: fixed;
  right: 0;
  top: 0;
`;

const BitmapImg = styled.img`
  width: 26vw;
  display: inline;
  opacity: 0;
  transition: 2s ease;
  position: absolute;
  @media (hover: none) {
    display: none;
  }
`;

const Index = ({ data }) => {
  console.log("20230306");
  const [activeImage, setActiveImage] = useState(null);
  const [hoverState, setHoverState] = useState(false);
  // const [stateRefreshVal, stateRefresher] = useState(0);

  let isPageWide = useMediaQuery("(min-width: 800px)");

  useEffect(() => {
    if (activeImage != null && hoverState == true) {
      elementsRef.current[activeImage].current.style.opacity = "1";
      setTimeout(function() {
        elementsRef.current[activeImage].current.style.opacity = "0";
      }, 2000);
    }
    // console.log(activeImage);
    // console.log(hoverState);
  }, [activeImage, hoverState]);

  const SingleArtistDesktop = ({ index, data, content }) => {
    var bgColor = content.node.data.project_background_color;

    function changeBackgroundColor() {
      document.body.style.backgroundColor = bgColor;
    }

    const mouseEnter = e => {
      // console.log("enter");
      changeBackgroundColor();
      setActiveImage(index);
      setHoverState(true);
      // stateRefresher(stateRefreshVal + 1);
    };
    const mouseLeave = i => {
      // console.log("leave");
      // stateRefresher(stateRefreshVal + 1);
      setHoverState(false);
    };
    return (
      <>
        <LiProjectsList
          onMouseEnter={mouseEnter}
          onMouseLeave={mouseLeave}
          index={index}
        >
          <Link to={content.node.uid}>
            {content.node.data.project_title.text}
          </Link>
        </LiProjectsList>
        <br></br>
      </>
    );
  };

  const artistListDesktop = data.allPrismicProject.edges.map(
    (content, index) => {
      return (
        <SingleArtistDesktop
          key={`artist_${index}`}
          index={index}
          data={data}
          content={content}
        />
      );
    }
  );

  const SingleArtistMobile = ({ index, data, content }) => {
    return (
      <>
        <LiProjectsList index={index}>
          <Link to={content.node.uid}>
            {content.node.data.project_title.text}
          </Link>
        </LiProjectsList>
        <br></br>
      </>
    );
  };

  const artistListMobile = data.allPrismicProject.edges.map(
    (content, index) => {
      return (
        <SingleArtistMobile
          key={`artist_${index}`}
          index={index}
          data={data}
          content={content}
        />
      );
    }
  );

  const elementsRef = useRef(
    data.allPrismicProject.edges.map(content => createRef())
  );

  const bitmapImages = data.allPrismicProject.edges.map((content, index) => {
    const styles = {
      left: `${content.node.data.index_image_position_left}vw`,
      top: `${content.node.data.index_image_position_top}vh`,
    };
    return (
      <BitmapImg
        style={styles}
        ref={elementsRef.current[index]}
        key={content.node.data.project_title.text}
        src={content.node.data.project_index_page_image.fluid.srcWebp}
      />
    );
  });

  return (
    <>
      <Helmet>
        <body className="index-page-body" />
        <title>Poppy Nash</title>
      </Helmet>
      <IndexPageCon>
        <NavCon>
          <ul>
            <LiNavList style={{ opacity: 1 }}>
              <Link to="/">Poppy Nash</Link>
            </LiNavList>
            <LiNavList>
              <Link to="/about">About</Link>
            </LiNavList>
          </ul>
        </NavCon>
        <ProjectListCon>
          <ul>{isPageWide ? artistListDesktop : artistListMobile}</ul>
        </ProjectListCon>
        <ImgCon>{bitmapImages}</ImgCon>
      </IndexPageCon>
    </>
  );
};

export default withPreview(Index);

export const query = graphql`
  {
    allPrismicProject(
      sort: { order: ASC, fields: data___project_title___text }
    ) {
      edges {
        node {
          uid
          id
          data {
            project_title {
              html
              text
            }
            project_index_page_image {
              fluid {
                srcSetWebp
                srcWebp
              }
            }
            index_image_position_left
            index_image_position_top
            project_background_color
          }
        }
      }
    }
  }
`;
